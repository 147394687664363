import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import "react-toastify/dist/ReactToastify.css";
//+++++++++++++++++++++++++++++++++++++++
import Home from "./home";
import { socket } from "./services/socket";
import { auth, getVersion } from "./services/index";
import { Chuls5 } from "./components/chuls/index";
import { Rides } from "./components/bus/index";
import { ProtectedRoute } from "./components/common/index";
import { StudentsPhone, Ontime } from "./components/students/index";
import { Docu } from "./components/concert/index";
import { LoginForm, Logout, NavBar, NotFound, RegisterForm } from "./components/index";
import { Evals, EvalForm, EvalJindo, EvalJindo1, EvalOnlyForm } from "./components/eval/index";
//+++++++++++++++++++++++++++++++++++++++
import "./App.css";

const user = auth.getCurrentUser()
const UserContext = React.createContext(user);
class App extends Component {
  constructor() {
    super()
    this.state = { user: auth.getCurrentUser() };
  }
  static contextType = UserContext;
  onUserChange = () => { this.setState({ user: auth.getCurrentUser() }) }
  async componentDidMount() {
    const { data } = await getVersion();
    this.setState({ version: data.version, stopUse: data.stopUse });
  }

  render() {
    const { user, version, stopUse } = this.state;
    const current_version = 2
    if (stopUse === true) {
      return <div className="alert alert-primary" role="alert">
        업그레이드 필요:[브라우저]의 [세팅]의 [방문기록]에서 그랜드음악학원(gma.forlang.kr) 방문기록을 삭제하세요.
    </div>
    }
    return (
      <UserContext.Provider value={auth.getCurrentUser()}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <>
            <ToastContainer />
            <NavBar user1={user} UserContext={UserContext} onUserChange={this.onUserChange} version={current_version} />
            {(version > current_version) && <div className="alert alert-primary" role="alert">
              업그레이드 필요:[브라우저]의 [세팅]의 [방문기록]에서 그랜드음악학원(gma.forlang.kr) 방문기록을 삭제하세요.
  </div>}
            <main className="container">
              <Switch>
                <Route path="/register" component={RegisterForm} />
                <Route path="/login" component={LoginForm} />
                <Route path="/logout" component={Logout} />
                <ProtectedRoute
                  path="/evals/:id"
                  render={props => <EvalForm {...props} user={user} socket={socket} />}
                />
                <ProtectedRoute
                  path="/eval/:id"
                  render={props => <EvalJindo {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/evalnew/:id"
                  render={props => <EvalJindo1 {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/evalsOnly/:id"
                  render={props => <EvalOnlyForm {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/chuls5"
                  render={props => <Chuls5 {...props} user={user} socket={socket} />}
                />
                <ProtectedRoute
                  path="/rides"
                  render={props => <Rides {...props} user={user} socket={socket} />}
                />
                <ProtectedRoute
                  path="/evals"
                  render={props => <Evals {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/docu"
                  render={props => <Docu {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/studentsPhone"
                  render={props => <StudentsPhone {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/ontime"
                  render={props => <Ontime {...props} user={user} />}
                />
                <Route path="/not-found" component={NotFound} />
                <Route path="/" component={Home} />
                <Redirect from="/" exact to="/" />
                <Redirect to="/not-found" />
              </Switch>
            </main>
          </>
        </MuiPickersUtilsProvider>
      </UserContext.Provider>
    );
  }
}

export default App;
