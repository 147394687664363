export const onCloseModal = (win, thisScope) => {
  if (win === "win1") thisScope.setState({ win1open: false });
  if (win === "win2") thisScope.setState({ win2open: false });
};

export const handleOpenWin = ({ student, thisScope }) => {
  thisScope.setState({
    win1open: true,
    modalStudent: student
  });
};

export const handleCancel = thisScope => {
  thisScope.setState({ win1open: false });
};