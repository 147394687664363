import React from "react";
import Modal from "react-responsive-modal";
import Filename from '../common/filename';
import { onCloseModal, handleCancel } from './pianosHandler'
import HiddenMenu from './hiddenMenu'
import { Button, LinkButton } from './button'
export const menuModal = ({
  win1open,
  cutClass,
  modalStudent,
  initStat,
  evaluate,
  history,
  thisScope,
  socket
}) => {
  const Buttons = [
    // {
    //   onClick: () => {
    //     thisScope.setState({
    //       win1open: false,
    //       win2open: true
    //     });
    //   },
    //   children: '피아노선택'
    // },
    {
      onClick: () => {
        cutClass(modalStudent, thisScope, socket);
        thisScope.setState({ win1open: false });
      },
      children: '오늘 결석'
    },
    {
      onClick: () => {
        initStat(modalStudent, thisScope, socket);
        thisScope.setState({ win1open: false });
      },
      children: '결석 취소'
    },
    {
      onClick: () => {
        initStat(modalStudent, thisScope, socket);
        thisScope.setState({ win1open: false });
      },
      children: '초기화'
    },
    {
      onClick: () => {
        evaluate(modalStudent, history.push);
        thisScope.setState({ win1open: false });
      },
      children: '평가'
    },
    {
      onClick: () => handleCancel(thisScope),
      children: '닫기'
    }
  ]
  return (
    <Modal
      open={win1open}
      onClose={() => onCloseModal("win1", thisScope)}
      center
      className="btn btn-warning"
    >
      {Filename('showPianos.jsx')}

      <HiddenMenu thisScope={thisScope} />
      <LinkButton student={modalStudent} href={`tel:010-${modalStudent.studPhone}`}> 학생콜 </LinkButton>
      {Buttons.map((b, index) =>
        <Button
          key={index}
          onClick={b.onClick}
          children={b.children}
        />
      )}
    </Modal>
  );
};