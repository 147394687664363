import React from "react";
import { Swipeable } from "react-touch";
import StudentBtn from "./studentBtn";
import { AnimatedButton } from "./animatedButton";
import legend from './legend'

const StudentsList = ({ ban }) => {
  const { showPic, showAjNum, onOpenWin, showTel, buttons, onClick } = ban
  return (
    <div className='ban' >
      {["레슨중", "등원", "레슨마침", "미출석", "하원", "결석"].map(
        kind => <div key={kind}>
          {buttons[kind].length > 0 ? legend(kind) : null}
          {buttons[kind].map(
            b => (
              <AnimatedButton b={b} handleClick={onClick} key={b._id} >
                <Swipeable
                  onSwipeLeft={() => onOpenWin(b)}
                  onSwipeRight={() => onOpenWin(b)}
                >
                  {StudentBtn(b, showPic, showAjNum, showTel)}
                </Swipeable>
              </AnimatedButton>
            ))
          }
        </div>)}
    </div>
  )
}
export default StudentsList