import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { socketOnFuncs, getStudents, getPianos, getLessonsWithDate } from "../../services/index1";
import { getDayOfWeek } from "../../utils/func";
import { cutClass, initStat, evaluate, getWeekAgo, getToday, Filename, MakeButtons, Loading } from "../common/index1";
import { handleClick, ch, handleSetDay, menuModal, handleOpenWin, Monitor, SwitchBoard } from "./index1";
import { addMinutes } from "./offTime"

// import { gql, useQuery } from '@apollo/client';

// const CHULS = gql`
// query Chuls{
//   chuls{
//     _id
//     name
//     pianoBan
//     ajNum
//     hag
//     age
//     status
//     onTime
//     pJin
//     theory
//     attend
//     attendNum
//     studPhone
//     profileImage
//     i1ignore
//     i2ignore
//     i3ignore
//     i4ignore
//     i5ignore
//     o1ignore
//     o2ignore
//     o3ignore
//     o4ignore
//     o5ignore
//     it1
//     ip1
//     ot1
//     op1
//     it2
//     ip2
//     ot2
//     op2
//     it3
//     ip3
//     ot3
//     op3
//     it4
//     ip4
//     ot4
//     op4
//     it5
//     ip5
//     ot5
//     op5
//   }
// }
// `;

const state = {
  chuls: [],
  day: [6, 0].includes(getDayOfWeek()) ? 1 : getDayOfWeek(), // 일 0, 월 1, 화 2, 수 3, 목 4, 금 5, 토 6, 토요일과 일요일은 월요일로 고친다.
  modalStudent: {},
  order: "name",
  pianos: [],
  showAjNum: false,
  showHak: false,
  showPic: false,
  showTel: false,
  showTime: false,
  win1open: false,
  win2open: false,
  counter: null,
  board1: null,
};

class Chuls extends Component {
  constructor(props) {
    super(props);
    this.state = state;
  }
  async setPianos() {
    let { data: pianos } = await getPianos();
    this.setState({ pianos });
  }
  async setChuls() {
    let { data: chuls } = await getStudents();
    let { data: times } = await getLessonsWithDate(getWeekAgo(getToday()));

    for (let c of chuls) {
      if (c.onTime === undefined) {
        c.onTime = "2000-02-01T00:00:00.000Z"; //신입생의 경우
      }
      if (c.lessonMinutes === undefined) {
        console.log("ERROR --> ", c.name, " lessonMinutes:", c.lessonMinutes)
        c.lessonMinutes = 40
      }

      c.offTime = String(addMinutes(c.onTime, c.lessonMinutes)).substring(11, 16);
      let a = JSON.stringify(c.onTime);
      c.onTime = a.substring(12, 17);
    }
    for (let time of times) {
      const index = chuls.findIndex(chul => chul._id === time.studentId);
      if (chuls[index] !== undefined) {
        chuls[index].time = JSON.stringify(time.onTime).substring(12, 17);
      }
    }
    this.setState({ chuls });
  }
  dataDown() {
    this.setPianos();
    this.setChuls();
  }
  async setShowPic() {
    const { location } = this.props;
    if (location !== undefined) {
      if (location.state !== undefined) {
        // if (location.state[0] !== undefined) {
        // this.setState({
        //   showPic: await location.state[0].showPic
        // });
        // }
      }
    }
  }
  async componentDidMount() {
    this.dataDown();
    this.setShowPic();
    let params = { room: "레슨", name: this.props.user.name };
    this.props.socket.emit("join", params, socketOnFuncs(this, this.props.socket));
  }

  render() {
    const { chuls, win1open, win2open, modalStudent, pianos } = this.state;
    const socket = this.props.socket;
    const menuParam = {
      win1open,
      cutClass,
      modalStudent,
      initStat,
      evaluate,
      history: this.props.history,
      thisScope: this,
      socket
    }
    const pianoParam = {
      win2open,
      modalStudent,
      pianos,
      thisScope: this,
      socket
    }
    const menuButtonParam = { history: this.props.history, thisScope: this, socket: this.props.socket }

    const buttons = MakeButtons({
      students: this.state.chuls,
      day: this.state.day,
      order: this.state.order,
      showTime: this.state.showTime,
      showHak: this.state.showHak
    })
    const ban = {
      showPic: this.state.showPic,
      showAjNum: this.state.showAjNum,
      onOpenWin: b => handleOpenWin({ student: b, thisScope: this }),
      showTel: this.state.showTel,
      buttons: buttons,
      onClick: handleClick(this, socket, this.props.history),
    };
    const board = {
      students: this.state.chuls,
      day: this.state.day,
    }

    if (chuls.length === 0) {
      return <Loading></Loading>;
    } else {
      return (
        <>
          {menuModal(menuParam)}
          {/* {showPianoModal(pianoParam)} */}
          {Filename('chuls/chuls5.jsx')}
          <SwitchBoard scope={this} onSetDay={handleSetDay} ch={ch} param={menuButtonParam} />
          <Monitor board={board} ban={ban} user={this.props.user} counter={this.state.counter} board1={this.state.board1} />
        </>
      );
    }
  }
}

export default withRouter(Chuls);
