import React from "react";
import MyImage from "./myImage";
import { 배지표시 } from "./chulsFunc";
import Info from './info'

const StudentBtn = (b, showPic, showAjNum, showTel) => (
  <div >
    {(showPic || b.showPic) && <MyImage img={`${b.name}`} />}
    {b.name}
    {배지표시(b)}
    <Info show={showAjNum} num={b.ajNum} />
    <Info show={showTel} num={b.studPhone} />
  </div >
);

export default StudentBtn;
