import React from 'react';
import fileName from '../common/func2';
import { socket } from '../../services/socket';
import Joi from 'joi-browser';
import Form from '../common/form';
import auth from '../../services/authService';
import { setStatus } from '../../utils/stat';
import { getStudent, saveStudent } from '../../services/studentService';
import { getSetting } from '../../services/settingService';

import { saveLesson } from '../../services/lessonService';
import { replaceAt } from '../../utils/func';
import { getTodayDateName } from '../common/chulsFunc';
const { 레슨마침 } = require('../../gma/Stats');

// 학부모편지 발행할때 사용하는 폼은 따로 저장되어있음: evalForm__saved
class EvalJindo extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        _id: '',
        name: '',
        status: '', //상태
        pJin: '', //피아노진도
        theory: '', //이론
        eval: '3333333', //평가 (기본값은 필드가 없을경우에도 에러나지않게 하기 위한것)
        letter: '', //학부모편지
        letterOld: '', //학부모편지
        eduDetail: '', //교육관련사항
        offDetail: '', //결석관련사항
      },
      disableSaveBtn: true,
      errors: {},
      fontColor: 'white'
    };
  }

  handleTextareaClick = (e) => {
    e.preventDefault()
    this.setState((prevState) => ({
      fontColor: prevState.fontColor === 'black' ? 'white' : 'black'
    }));
  };

  schema = {
    _id: Joi.string(),
    name: Joi.string()
      .required()
      .label('이름'),
    status: Joi.string()
      .allow(null, '')
      .label('상태'),
    pJin: Joi.string()
      .allow(null, '')
      .label('피아노진도'),
    theory: Joi.string()
      .allow(null, '')
      .label('이론'),
    eval: Joi.string()
      .allow(null, '')
      .label('평가'),
    letter: Joi.string()
      .allow(null, '')
      .label('학부모편지'),
    letterOld: Joi.string()
      .allow(null, '')
      .label('전월 학부모편지'),
    eduDetail: Joi.string()
      .allow(null, '')
      .label('교육관련사항'),
    offDetail: Joi.string()
      .allow(null, '')
      .label('결석관련사항'),
  };
  //todo: 여기 이 파일에는 eval 필드 없으면 추가해 줄것
  //todo: evalOnlyForm.jsx 와 합칠것
  async populateStudent() {
    try {
      const studentId = this.props.match.params.id;
      if (studentId === 'new') return;

      const { data: student } = await getStudent(studentId);
      this.setState({
        data: this.mapToViewModel(student),
        disableSaveBtn: false,
      });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace('/not-found');
    }
  }

  async componentDidMount() {
    await this.populateStudent();
    getSetting().then(({ data }) => {
      this.setState({ setting: data[0] });
    });
    window.scrollTo(0, 0);
  }

  mapToViewModel(student) {
    return {
      _id: student._id,
      name: student.name,
      status: student.status,
      pJin: student.pJin,
      theory: student.theory,
      eval: student.eval,
      letter: student.letter,
      letterOld: student.letterOld,
      eduDetail: student.eduDetail,
      offDetail: student.offDetail,
    };
  }
  lessonSubmit = async (lesson) => await saveLesson(lesson);
  user = auth.getCurrentUser();

  doSubmit = async () => {
    await saveStudent(this.state.data);
    const { _id, name, pJin, theory, eduDetail, offDetail } = this.state.data;
    const lesson = {
      // lesson details
      date: getTodayDateName(),
      student: name,
      studentId: _id,
      pJin: pJin,
      theory: theory.replace(/ /g, ''),
      teacher: this.user.name,
      eduDetail: eduDetail,
      offDetail: offDetail,
    };
    if (this.user.name !== 'admin') {
      // 선생님이 아니면 레슨기록을 하지 않는다. debug시 주의하시오.
      this.lessonSubmit(lesson); // 레슨 기록 작성
    }
    let student = this.state.data;
    setStatus(student, 레슨마침);
    saveStudent(student);
    socket.emit(
      'status',
      {
        user: this.user._id,
        name: student.name,
        _id: student._id,
        status: student.status,
      },
      function(err) {
        //      if (err) {
        //        this.props.history.push("/");
        //      }
      }
    );
    this.props.history.goBack();
  };

  handleRadioChange = (index, value) => {
    let data = this.state.data;
    const eval1 = data.eval;
    data.eval = replaceAt(eval1, index * 1, value);
    this.setState({ data });
  };

  addChar = (char1) => {
    const data = this.state.data;
    data.letter += char1;

    this.setState({ data });
  };
  
  render() {
    console.log('11',this.state)
    return (
      <>
        <div>{fileName('recordTodayLesson.jsx', this.user)}</div>
        <form onSubmit={this.handleSubmit}>
          <div className="badge badge-success">피아노진도</div>
          <div className="form-group">
            {this.renderTextarea('pJin', '피아노진도', 'text', 'col-sm-12')}
          </div>
          <div className="badge badge-success">이론</div>
          <div className="form-group">
            {this.renderTextarea('theory', '이론', 'text', 'col-sm-12')}
          </div>
          <div className="badge badge-success" onClick={this.handleTextareaClick}>교육관련사항</div>
          <div className="form-group">
            {this.renderTextarea1(
              'eduDetail',
              '교육관련사항',
              'text',
              'col-sm-12'
            )}
          </div>
          {/* <button onClick={this.handleTextareaClick}
      >star</button> */}
          {this.renderButton1(this.state.disableSaveBtn)}
        </form>
      </>
    );
  }
}

export default EvalJindo;
